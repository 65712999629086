// url: /product/:id
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { productsRestService } from 'rest/products';

import "./styles.scss";

function ProductIdPage() {
  const [product, setProduct] = useState();
  const [activeImage, setActiveImage] = useState();
  const { id } = useParams();

  useEffect(() => {
    productsRestService.getOne(id).then(product => {
      setProduct(product);
      setActiveImage(product.images[0]);
      document.title = product.title;
    })
  }, []);

  const selectImage = imageUrl => () => {
    setActiveImage(imageUrl);
  }

  return (
    <div className="container flex-grow-1">
      { product
        ? <>
            <div className="row">
              <div className="col">
                <div className="site-info p-1">
                  <h1>{product.title}</h1>
                  <div className="h1 bold">{product.price} {product.currency}</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <img
                  className="main-image mb-1"
                  src={activeImage}
                  alt={product.title} />

                  <div className="d-flex" style={{ gap: "4px", overflow: "auto", width: "100%" }}>
                    {product.images.map((image, i) =>
                      <div key={'image' + i}>
                        <img
                          className={`carousel-image ${activeImage == image ? "active" : null}`}
                          src={image} alt={image}
                          onClick={selectImage(image)} />
                      </div>
                    )}
                  </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="h4 mt-4">Опис</div>
                <p className="lead mb-4">{product.description}</p>

                <div className="card">
                  <table className="table table-striped mb-0">
                    <tbody>
                      {product.specifications.map((char, specKey) =>
                        <tr key={'spec' + specKey}>
                          {char.map((col, specLineKey) => <td key={'specLine' + specLineKey}>{col}</td>)}
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="buy-element subtitle-1 mt-4" style={{ marginLeft: "auto" }}>
                  Купівля \ питання за телефоном: <a href="tel:+380971522468">(+380) 97 152 2468</a>
                </div>
              </div>
            </div>

          </>
        : <div className="d-flex justify-content-center">
            <div className="spinner-border m-5">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
      }
    </div>
  );
}

export default ProductIdPage;
