import './styles.scss';

export const NotFoundPage = () => {
  return (
    <div className='content not-found'>
      <i className="fa-sharp fa-regular fa-circle-xmark"></i>
      <span className='h4'>404</span>
      | сторінку не знайдено
    </div>
  );
}

export default NotFoundPage;
