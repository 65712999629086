const isDev = process.env.NODE_ENV === 'development';

const _client = (path, body, method = 'get', params = {}) => {
  return fetch(getUrl(path),
    {
      credentials: 'include',
      method: method.toUpperCase(),
      body: body && JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      },
      ...params
    }
  ).then(resp => resp.json())
}

export const get = (path, params) => {
  if (params && Object.keys(params).length) {
    const keys = Object.keys(params);

    path += '?' + keys
      .map(key => (`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`))
      .join('&');
  }

  return _client(path, null, 'GET', params);
}

export const post = (path, body, params) => {
  return _client(path, body, 'POST', params);
}

export const update = (path, body, params) => {
  return _client(path, body, 'PUT', params);
}

export const remove = (path, params) => {
  return _client(path, params, 'DELETE');
}

export const api = {
  get,
  post,
  update,
  remove
}

function getUrl(path = '') {
  if (isDev) {
    return location.protocol + '//'
    + location.hostname
    + ':'
    + process.env.REACT_APP_BE_PORT
    + path;
  } else {
    return location.protocol + '//'
    + location.hostname
    + '/' + process.env.REACT_APP_BE_PREFIX
    + path;
  }
}
