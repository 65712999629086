import { useEffect, useState } from 'react';

import { get, post } from 'rest/api';

import './styles.scss';

import AdminPanel from './_admin-panel';

export const ConsolePage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    get('/')
      .then(res => {
        setUser(res)
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const { email, password } = e.target;

    post('/auth/login', { email: email.value, password: password.value })
      .then(res => {
        if (res.error) console.log(res);
        setUser(res)
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }

  const spinner = () => {
    if (loading) {
      return <div className="fullscreent-spinner" style={{ position: 'absolute' }}>
        <div className="spinner-border text-secondary">
          <span className="sr-only"></span>
        </div>
      </div>
    } else {
      return null;
    }
  }

  if (!user || user.error) {
    return <div className="content-center">
      <form onSubmit={handleSubmit} className="container p-2 align-items-center">
        <div className="row justify-content-center mb-2">
          <div className="col-6">
            <label htmlFor="email">Авторизуйтесь</label>
            <input type="email" id='email' className="form-control mb-2" placeholder="Введіть імейл" />
            <input type="password" name='password' className="form-control" placeholder="Пароль" />
            <small className="form-text text-muted">Пам'ятаєм що це секретна інформація</small>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-6">
            {user && user.error && <div className="alert alert-danger">
              {user.error}
            </div>}
            <button type="submit" className="btn btn-primary w-100">Зайти</button>
          </div>
        </div>
      </form>

      {spinner()}
    </div>
  } else {
    return <div className='content'>
      <AdminPanel user={user} setUser={setUser} />

      {spinner()}
    </div>
  }
}

export default ConsolePage;
