import { useEffect, useState } from 'react';

import { currencyArray } from 'enums/product';

import { post } from 'rest/api';
import { productsRestService } from 'rest/products';

import './styles.scss';

export const AdminPanel = ({ user, setUser }) => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setLoading(true);
    productsRestService.getAll()
      .then(res => {
        setProducts(res || []);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const handleLogout = () => {
    setLoading(true);
    post('/auth/logout')
      .then(res => {
        if (res.error) return console.log(res);
        setUser(null);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }

  const handleAddEmptyProduct = () => {
    if (products.some(product => product.id === 'new')) return;

    const emptyProduct = ({
      _id: 'new',
      title: 'Новий продукт',
      description: 'Опис продукту',
      specifications: [
        ['Колір', 'Чорний'],
      ],
      price: 0,
      currency: 'грн',
      images: [],
      // for UI
      edit: true,
    });

    setProducts([emptyProduct, ...products]);
  }

  const handleUpdateProduct = (productId) => {
    const updatedProduct = products.find(product => product._id === productId);
    if (!updatedProduct) return;

    if (updatedProduct._id == 'new') {
      delete updatedProduct._id;

      setLoading(true);
      productsRestService.create(updatedProduct)
        .then(res => {
          if (res.error) return console.log(res);

          delete updatedProduct.edit;

          setProducts(products.map(product => 'new' === productId ? res : product));
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false));
    } else {
      setLoading(true);
      productsRestService.update(updatedProduct._id, updatedProduct)
        .then(res => {
          if (res.error) return console.info(res);

          delete updatedProduct.edit;

          setProducts(products.map(product => product._id === productId ? res : product));
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false));
    }
  }

  const handleRemoveProduct = (productId) => {
    setLoading(true);
    productsRestService.remove(productId)
      .then(res => {
        if (res.error) return console.log(res);
        setProducts(products.filter(product => product._id !== productId));
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }

  const handleFilesUpload = (e, productIndex) => {
    const files = e.target.files;
    if (!files) return;
    const images = [...products[productIndex].images];

    Array.from(files).forEach(file => {
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.onload = () => {
        images.push(fr.result);
        const newProducts = [...products];
        products[productIndex].images = images;
        setProducts(newProducts);
      };
    });
  };

  const rows = products.map((product, productIndex) =>
    <tr key={'product' + productIndex}>
      <th scope='row' className='align-middle'>{productIndex + 1}</th>
      <td
        style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '5rem' }}
        title={product._id}
        className='align-middle'>
        {product._id}
      </td>
      <td>
        <input
          type='text'
          className='form-control'
          value={product.title}
          disabled={!product.edit}
          onChange={e => {
            const newProducts = [...products];
            products[productIndex].title = e.target.value;
            setProducts(newProducts);
          }}
        />
      </td>
      <td>
        <textarea
          className='form-control'
          rows={1}
          value={product.description}
          disabled={!product.edit}
          onChange={e => {
            const newProducts = [...products];
            products[productIndex].description = e.target.value;
            setProducts(newProducts);
          }}
        />
      </td>
      <td>
        <div className='align-middle d-flex gap-1 flex-column'>
          {product.specifications.map((spec, specIndex) =>
            <div key={'spec' + productIndex + specIndex}>
              <div key={'spec' + productIndex + specIndex} className='d-flex gap-1'>
                <input
                  type='text'
                  className='form-control'
                  value={spec[0]}
                  disabled={!product.edit}
                  onChange={e => {
                    const newProducts = [...products];
                    products[productIndex].specifications[specIndex][0] = e.target.value;
                    setProducts(newProducts);
                  }}
                />
                <input
                  type='text'
                  className='form-control'
                  value={spec[1]}
                  disabled={!product.edit}
                  onChange={e => {
                    const newProducts = [...products];
                    products[productIndex].specifications[specIndex][1] = e.target.value;
                    setProducts(newProducts);
                  }}
                />
                {specIndex == product.specifications.length - 1 && product.edit &&
                  <button
                    style={{ minWidth: '2.5rem' }}
                    className='btn btn-info'
                    onClick={() => {
                      const newProducts = [...products];
                      products[productIndex].specifications.push(['', '']);
                      setProducts(newProducts);
                    }}>
                    +
                  </button>
                }
                {specIndex < product.specifications.length - 1 && product.edit &&
                  <button
                    style={{ minWidth: '2.5rem' }}
                    className='btn btn-info'
                    onClick={() => {
                      const newProducts = [...products];
                      products[productIndex].specifications.splice(specIndex, 1);
                      setProducts(newProducts);
                    }}>
                    -
                  </button>
                }
              </div>
            </div>
          )}
        </div>
      </td>
      <td>
        <input
          type='number'
          className='form-control'
          value={product.price}
          disabled={!product.edit}
          onChange={e => {
            const newProducts = [...products];
            products[productIndex].price = e.target.value;
            setProducts(newProducts);
          }}
        />
      </td>
      <td>
        <select
          className='form-control'
          value={product.currency}
          disabled={!product.edit}
          onChange={e => {
            const newProducts = [...products];
            products[productIndex].currency = e.target.value;
            setProducts(newProducts);
          }}
        >
          {currencyArray.map((currency, currencyIndex) =>
            <option key={'currency' + currencyIndex} value={currency}>
              {currency}
            </option>
          )}
        </select>
      </td>
      <td>
        <input
          type='file'
          multiple
          disabled={!product.edit}
          className='form-control'
          onChange={(e) => handleFilesUpload(e, productIndex)} />
        <div className='images-container'>
          {product.images.map((image, imageIndex) =>
            <div key={'image' + productIndex + imageIndex} className='image-wrapper'>
              <img src={image} className='image-preview' />
              {product.edit
                ? <div className='overlay' onClick={() => {
                    const newProducts = [...products];
                    products[productIndex].images.splice(imageIndex, 1);
                    setProducts(newProducts);
                  }}>
                    <i className="bi bi-trash3"></i>
                  </div>
                : null}
            </div>
          )}
        </div>
      </td>
      <td>
        <div className='d-flex gap-1'>
          {product.edit
            ? <>
              <button className='btn btn-primary' onClick={() => handleUpdateProduct(product._id)}>
                <i className="bi bi-save"></i>
              </button>
            </>
            : <>
              <button
                className='btn btn-primary'
                onClick={() => {
                  const newProducts = [...products];
                  products[productIndex].edit = true;
                  setProducts(newProducts);
                }}>
                <i className="bi bi-pencil"></i>
              </button>
            </>
          }
          <button className='btn btn-danger' onClick={() => handleRemoveProduct(product._id)}>
            <i className="bi bi-trash"></i>
          </button>
        </div>
      </td>
    </tr>
  )

  return (
    <div className='admin-panel content'>
      <div className='fluid-container p-2'>
        <div className='row mb-1'>
          <div className='col'>
            <button className='btn btn-primary' onClick={handleAddEmptyProduct}>
              Додати продукт
            </button>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <table className='table'>
              <thead>
                <tr>
                  <th scope='col'>#</th>
                  <th scope='col'>ID</th>
                  <th scope='col'>Заголовок</th>
                  <th scope='col'>Опис</th>
                  <th scope='col'>Характеристики</th>
                  <th scope='col'>Ціна</th>
                  <th scope='col'>Валюта</th>
                  <th scope='col'>Картинки</th>
                  <th scope='col'>Дії</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <button className='btn btn-secondary' onClick={handleLogout}>Вийти</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminPanel;
