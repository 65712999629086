// import logo from 'assets/images/logo.png';
import { Link } from 'react-router-dom';

import './styles.scss';

export const Header = () => {
  return (
    <header className="p-3 text-bg-dark">
      <div className="container">
        <div className="col-12 d-flex flex-wrap align-items-center justify-content-between">
          <Link to={'/'} className="d-flex align-items-center text-white text-decoration-none">
            Ground.com.ua
          </Link>

          {/* <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
            <li><a href="#" className="nav-link px-2 text-secondary">Home</a></li>
            <li><a href="#" className="nav-link px-2 text-white">Features</a></li>
            <li><a href="#" className="nav-link px-2 text-white">Pricing</a></li>
            <li><a href="#" className="nav-link px-2 text-white">FAQs</a></li>
            <li><a href="#" className="nav-link px-2 text-white">About</a></li>
          </ul> */}

          {/* <form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3" role="search">
            <input type="search" className="form-control form-control-dark text-bg-dark" placeholder="Search..." aria-label="Search" />
          </form> */}
          <a href="tel:+380971522468" className="text-white">(+380) 097 15 22468</a>
        </div>
      </div>
    </header>
  );
}

export default Header;
