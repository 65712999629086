import { Routes, Route } from 'react-router-dom';

import Header from 'pages/_header';
import Footer from 'pages/_footer';

import TitlePage from 'pages/title-page';
import ConsolePage from './console';
import NotFoundPage from './404';
import ProductIdPage from './product-id-page';

import 'bootstrap-icons/font/bootstrap-icons.css';
import './root.scss';

function AppRouter() {
  return (<>
    <Header />
    <Routes>
      <Route path="/" element={<TitlePage />} />
      <Route path="/console" element={<ConsolePage />} />
      <Route path="/product/:id" element={<ProductIdPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
    <Footer />
  </>);
}

export default AppRouter;
