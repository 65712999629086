import { api } from 'rest/api'

export const productsRestService = {
  getAll: () => {
    return api.get('/products')
      .then((response) => {
        return response
      })
    },
  getOne: (id) => {
    return api.get(`/products/${id}`)
      .then((response) => {
        return response
      })
  },
  create: (product) => {
    return api.post('/products', product)
      .then((response) => {
        return response
      })
  },
  update: (id, product) => {
    return api.update(`/products/${id}`, product)
      .then((response) => {
        return response
      })
  },
  remove: (id) => {
    return api.remove(`/products/${id}`)
      .then((response) => {
        return response
      })
  }
}

const stubData = [
  {
    id: 1,
    title: 'Металошукач Pirat з li-ion акумулятором',
    describe: 'Імпульсний металошукач "Пірат", початкового рівня, для пошуку великих металевих предметів на глибині до пів метра.',
    characteristics: [
      ['Монета', '10-15 см.'],
      ['Лопата', '40-50 см.'],
      ['Акумулятор', 'Li-ion (до 30 годин на одному заряді)'],
      ['Контроль заряду/розряду', 'Мікропроцесор Microchip. (подовжує строк служби акумулятора)'],
      ['Електронний блок', '1 шт.'],
      ['Зарядний пристрій', '1 шт.'],
      ['Вага', '1 кг.'],
      ['Гарантія', '1 рік.']
    ],
    price: 2000,
    currency: 'грн.',
    images: []
  },
  {
    id: 2,
    title: 'Металошукач ClonePi AVR',
    describe: 'Металошукач ClonePi AVR з посиленим корпусом та вологостійкістю.',
    characteristics: [
      ['Корпус', 'Текстоліт'],
      ['Метали не розрізняє', ''],
      ['Чутливість з датчиком 25 см:', ''],
      ['5 коп СССР:', '20-25 см'],
      ['Лопата:', '60-70 см'],
      ['Габаритні предмети', 'до 1 м.'],
      ['Чутливість з датчиком 50х50 см (в комплект не входить):', 'до 2х метрів'],
      ['Чутливість з рамкою 1х1 метр (в комплект не входить)', 'до 3х метрів'],
      ['Акумулятор', 'Li-ion (9-12 годин на одному заряді)'],
      ['Комплектація:', ''],
      ['Електронний блок', '1 шт.'],
      ['Складна штанга (метал, пластик)', '1 шт.'],
      ['Датчик 25 см.', '1 шт.'],
      ['Зарядний пристрій', '1 шт.'],
      ['Гарантія', '1 рік.'],
      ['Вага', '1 кг.'],
    ],
    price: 4300,
    currency: 'грн.',
    images: []
  },
  {
    id: 3,
    title: 'Крейсер Москва декоративний. Для акваріуму, на полицю. 50 см.',
    describe: 'Декоративний крейсер Москва з пробоїнами. Можна прикрасити дно акваріуму.',
    characteristics: [
      ['Метареіал', 'Пластик ABS, лак'],
      ['Розміри', '50 см.'],
    ],
    price: 1500,
    currency: 'грн.',
    images: []
  },
  {
    id: 4,
    title: 'Крейсер Москва декоративний. Для акваріуму, на полицю. 30 cм.',
    describe: 'Декоративний крейсер Москва з пробоїнами. Можна прикрасити дно акваріуму.',
    characteristics: [
      ['Метареіал', 'Пластик ABS, лак'],
      ['Розміри', '30 см.'],
    ],
    price: 800,
    currency: 'грн.',
    images: []
  },
  {
    id: 5,
    title: 'Крейсер Москва декоративний. Для акваріуму, на полицю. 20 см.',
    describe: 'Декоративний крейсер Москва з пробоїнами. Можна прикрасити дно акваріуму.',
    characteristics: [
      ['Метареіал', 'Пластик ABS, лак'],
      ['Розміри', '20 см.'],
    ],
    price: 700,
    currency: 'грн.',
    images: []
  },
]

