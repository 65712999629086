export const currency = {
  uah: 'грн.',
  usd: '$',
  eur: '€',
}
export const currencyArray = Object.values(currency);

export const productType = {
  id: 'number',
  title: 'string',
  description: 'string',
  specifications: 'array',
  price: 'number',
  currency: currency,
  images: 'array',
}
