import { Link } from 'react-router-dom';

import './styles.scss';

const EMAIL = 'sale.ground.com.ua@gmail.com';

export const Footer = () => {
  return (
    <footer style={{ marginTop: 'auto' }}>
      {new Date().getFullYear()} рік
      <a href={`mailto:${EMAIL}`} target='_blank' rel='noreferrer' style={{ marginLeft: '6px' }}>{EMAIL}</a>
      <Link to={`/console`} className='btn'></Link>
    </footer>
  );
}

export default Footer;
