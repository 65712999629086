import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { productsRestService } from 'rest/products';

import './styles.scss';

export const TitlePage = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setLoading(true);
    productsRestService.getAll()
      .then(res => {
        if (res.error) {
          setProducts([]);
        } else {
          setProducts(res)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));

    document.title = 'Металошукачі ClonePI AVR, Pirat від виробника';
  }, [])

  return (
    <main className='container flex-column'>
      <div className='row'>
        <h1 className='col'>
          Металошукачі та комплектуючі до них
          <b> від виробника</b>
        </h1>
      </div>

      <div className='row'>
        <div className='col'>
          Виготовляємо в Україні, відправляємо Новою Поштою де вона наразі доступна.<br/>
        </div>
      </div>

      <hr className='hr' />

      <div className='row'>
        {products
          ? products.map(product =>
            <div className='col-12 col-sm-6 col-md-4 col-xl-3' key={product.title}>
              <div className='card mb-2'>
                <div className='ratio ratio-16x9'>
                  <img
                    style={{ objectFit: 'cover' }}
                    className='card-img-top'
                    src={product.images[0]}
                    alt={product.title} />
                </div>
                <div className='card-body'>
                  <h5 className='card-title'>{product.title}</h5>
                  <p className='card-text'>{product.price} {product.currency}</p>
                  <Link to={`/product/${product._id}`} className='btn btn-dark'>Огляд</Link>
                </div>
              </div>
            </div>
            )
          : <div className='flex-center w-100'>
              <i className='fa fa-spinner fa-spin h1'></i>
            </div>
        }
      </div>
    </main>
  );
}

export default TitlePage;
